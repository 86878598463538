import React from 'react';
import '../../App.css';
import Footer from '../Footer'


export default function AboutMe() {
  return (
    <>
      <div class="header">
        <h1>Why I do Animal Led Reiki</h1>
        </div>
        <div class="card">
          <div>
            <p>My Name is Tracy and I became interested in Reiki over 10 years ago through my late Yoga teacher, Eily Hall.</p>
          </div>
            <p>Eily taught me the power of healing through Reiki, where she helped me overcome a medical condition. From this and with my love of animals, I embarked on a journey of self discovery in the realm of Reiki.</p>
            <p>I became even more determined to learn and harness the power of Reiki, when my own beloved dog, Esme, became gravelly ill. With the vet informing me to prepare for the worst I put faith in Reiki and myself to successfully heal her. I felt empowered to use this enlightenment to hone my skills so that I could offer Reiki to more animals to help them through troublesome and different times.</p>
            <p>TC Animal Reiki has been founded to offer 'Animal Led' healing using the approach of practicing Reiki through meditating with the animals not doing it to them.</p>
        </div>
        <div class="card">
            <h3>Training</h3>
            <p>I have since invested a lot of time learning the skills to read and speak to my animals and am now a member of the Reiki Society</p>
        </div>
      <Footer />
    </>
  );
}
