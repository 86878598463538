import React, {useEffect, useState} from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/images/rsz_1rsz_tc_animal_reiki_logo.png'

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
          <nav className='navbar'>
              <div className='navbar-container'>
                  <Link to='/' className='navbar-logo' onClick={closeMobileMenu}> 
                      <img src={logo} className='logo' alt='logo' />
                  </Link>
                  <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                  </div>
                  <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                      <li className='nav-item'>
                          <Link 
                            to='/' 
                            className='nav-links' 
                            onClick={closeMobileMenu}>
                              Home
                          </Link>
                      </li>
                      <li className='nav-item'>
                          <Link 
                            to='/services' 
                            className='nav-links' 
                            onClick={closeMobileMenu}
                            >
                              Services
                          </Link>
                      </li>
                      <li className='nav-item'>
                          <Link 
                            to='/reiki' 
                            className='nav-links' 
                            onClick={closeMobileMenu}
                            >
                              About Reiki
                          </Link>
                      </li>
                      <li className='nav-item'>
                          <Link 
                            to='/about-me' 
                            className='nav-links' 
                            onClick={closeMobileMenu}
                            >
                              About Me
                          </Link>
                      </li>
                      <li>
                          <Link 
                            to='/contact-us' 
                            className='nav-links-mobile' 
                            onClick={closeMobileMenu}
                            >
                              Book Now 
                          </Link>
                      </li>
                  </ul>
                  {button && <Button buttonStyle='btn--outline'>Book Now</Button>}
              </div>
          </nav>
        </>
    );
}

export default Navbar;
