import React from 'react';
import '../pages.css';
import Footer from '../Footer'


function WhatIsReiki() {
  return (
    <>
      <div class="header">
        <h1>The Nature of Reiki Healing</h1>
        </div>
        <div class="card">
          <div>
            <p>Reiki is a holistic system of energy healing. Practitioners connect with healing energy in the universe and channel it through their hands. Once limited to primarily healing people, it now also benefits companion animals around the globe. A growing number of animal rescues are taking advantage of its healing benefits.</p>
          </div>
            <p>Reiki is a natural healing energy activated by intention. It works on every level, not just the physical. The practice of Reiki is an original method of healing, developed by Mikao Usui in Japan early in the 20th century.</p>
            <p>The Japanese word Reiki means Universal Energy'. Eastern medicine has always recognised and worked with this energy, which flows through all living things and is vital to well-being; known as 'ki' in Japan, 'chi' in China and prana' in India. Acupuncture, Tai chi and Yoga are also based on the free-flow of this energy.</p>
        </div>
        <div class="card">
            <h5>SAFETY</h5>
            <p>Reiki is non-invasive, gentle yet powerful, and should be used with confidence alongside orthodox healthcare. As such, it expands treatment options for all animals and pets. Reiki is not, however, an alternative to conventional medicine. You should consult a Veterinary Practitioner for diagnosis prior to seeking Reiki for your animal/pet if a physical health issue is suspected. For acute or infectious conditions or for problems of an urgent nature you must also consult the Veterinary surgeon in the first instance. Reiki Practitioners do not diagnose, or advise on veterinary treatments.</p>
        </div>
      <Footer />
    </>
  );
}

export default WhatIsReiki