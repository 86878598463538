import React from 'react';
// import '../../App.css';
import '../pages.css'
import ServiceCards from '../ServiceCards';
// import Cards from '../Cards';
import Footer from '../Footer';

export default function Services() {
  return (
    <>
      <div class="header">
        <h1>Services</h1>
        </div>
      <ServiceCards />
      <Footer />
    </>
  );
}
