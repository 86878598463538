import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

export default function Cards() {
  return (
    <div className='cards'>
      <h1>Below are the services I offer! Click to find out more and book your free telephone consultation</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/distance-reiki.jpg'
              text='Distance Animal Reiki'
              label='Reiki'
              path='/session'
            />
            <CardItem
              src='images/reiki_session.jpg'
              text='One-2-One Reiki Healing Session'
              label='Reiki'
              path='/session'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/crystal.jpg'
              text='Crystal Healing one to one session'
              label='Crystals'
              path='/session'
            />
            <CardItem
              src='images/animal-chakra.png'
              text='Chakra balancing'
              label='Chakra'
              path='/session'
            />
            <CardItem
              src='images/crystal-chakra.jpg'
              text='Crystal Chakra Balancing'
              label='Chakra Crystals'
              path='/session'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}
