import React from 'react';
import '../pages.css';
import Footer from '../Footer'


export default function Testimonials() {
  return (
    <>
      <div class="header">
        <h1>Testimonials</h1>
        </div>
        <div class="card">
          <div>
            <p>Treacle absolutely loves her reiki sessions. She always seems that little bit more relaxed and happier after each session, as if she has made any of her problems known and a weight has been lifted from her. She enjoys every moment in the sessions and is very comfortable with Tracy being in her space</p>
          </div>
        </div>
      <Footer />
    </>
  );
}
