import React from 'react';
import '../../App.css';
import Footer from '../Footer'


export default function Reiki() {
  return (
    <>
      <div class="header">
        <h1>The Nature of Reiki Healing</h1>
        </div>
        <div class="card">
          <p>Reiki is a holistic system of energy healing. Practitioners connect with healing energy in the universe and channel it through their hands. Once limited to primarily healing people, it now also benefits companion animals around the globe. A growing number of animal rescues are taking advantage of its healing benefits.</p>
          <p>This healing system is easy to learn. The ability to utilise it is transferred to students during a class that usually lasts several hours.</p>
        </div>
        <h3>Benefits of Animal Reiki</h3>
        <div class="card">
          <p>This healing system is safe and will never harm an animal. If an animal has experienced abuse or neglect, Reiki helps it to release emotional wounds. Treating horses and pets with Reiki helps relax and calm them, making it easier for owners and trainers to handle them and resolve various issues. A calmer animal means a calmer, happier owner.</p>
          <p>Reiki is extremely useful in conjunction with traditional veterinary care. It helps animals recover faster, saving owners anxiety and cost. Veterinarians trained in this type of healing have found it useful in treating travel stress and digestive problems, as well as settling mares who are new mothers.</p>
        </div>
        <div class="card">
          <p>Equine Reiki is also beneficial as a horse approaches the end of its life. It can provide comfort to a beloved animal, its owners and the staff members who provide daily care. Interested in Equine Reiki to assist the wellbeing of your horse? See the IARP web site to find a practitioner in your area.</p>
        </div>
        <div class="card">
          <p>**This article appeared in The Reiki Times, the official magazine of the International Association of Reiki Professionals.</p>
        </div>
      <Footer />
    </>
  );
}
