import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Click to learn more or hear from my customers!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/reiki_healing.jpg'
              text='The Nature of Reiki Healing'
              label='Reiki'
              path='/reiki'
            />
            <CardItem
              src='images/reiki_session.jpg'
              text='What Happens During a Reiki Session'
              label='Sessions'
              path='/session'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/reiki.jpg'
              text='What is Reiki'
              label='Reiki'
              path='/whatisreiki'
            />
            <CardItem
              src='images/reiki_session.jpg'
              text='What my customers have said'
              label='Comments'
              path='/comments'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
