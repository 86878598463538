import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Reiki from './components/pages/Reiki';
import Services from './components/pages/Services';
import WhatIsReiki from './components/pages/WhatIsReiki';
import Sessions from './components/pages/Session';
import BookNow from './components/pages/BookNow';
import Testimonials from './components/pages/Testimonials';
import Terms from './components/pages/Terms';
import AboutMe from './components/pages/AboutMe';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/services' component={Services} />
        <Route path='/reiki' component={Reiki} />
        <Route path='/about-me' component={AboutMe} />
        <Route path='/contact-us' component={BookNow} />
        <Route path='/whatisreiki' component={WhatIsReiki} />
        <Route path='/session' component={Sessions} />
        <Route path='/comments' component={Testimonials} />
        <Route path='/terms' component={Terms} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
