import React from 'react';
import '../pages.css';
import Footer from '../Footer';

function Sessions() {
    return (
        <>
        <div class="header">
        <h1>WHAT HAPPENS DURING A SESSION?</h1>
        </div>
        <div class="card">
            <h2>What Should I Expect?</h2>
            <p>Reikì does not involve the application of pressure and there is no massage or manipulation.</p>
            <p>A Reiki session with an animal will be led by the animal's preference in terms of proximity and, like human treatments, often involves 'hands on/ hands near' contact. The environment will typically be familiar and quiet. If the animal is uncertain the practitioner can send the Reiki from a 'distance'. If the animal chooses to receive their Reiki whilst in their stable, kennel or other 'safe place' this will not reduce the benefit of the Reiki for the animal as the energy travels through material naturally. Horses and other animals are sensitive to the energy and, like humans, generally find it relaxing and comforting.</p>
            <p>Reiki may be experienced as a flow of energy, mild tingling, warmth, coolness, other sensations, or nothing at all.</p>
            <p>The length of Reiki sessions may vary, but are usually half to one hour in duration.</p>
            <p>The Practitioner will take a short case history from the animal's carer prior to offering Reiki. A treatment plan will be discussed, agreed and reviewed as necessary, subject to indicated preferences, it can be changed at any time in line with progress.</p>
        </div>
        <div class="card">
            <h3>Distance Animal Reiki</h3>
            <h5>30 minutes · £20</h5>
            <p>Distance session of Animal Reiki with discussion afterwards.</p>
        </div>
        <div class="card">
            <h3>One-2-One Reiki Healing Session</h3>
            <h5>30 minutes and over · Price varies</h5>
            <p>One to one session - typically 30mins to an hour, including case history and after session discussion.</p>
        </div>
        <div class="card">
            <h3>Crystal Healing one to one session</h3>
            <h5>30 minutes and over · Price varies</h5>
            <p>Typically 30 mins to an hour based on your animals needs if they require a more directed healing session. This will usually follow initial consultation.</p>
        </div>
        <div class="card">
            <h3>Chakra balancing</h3>
            <h5>30 minutes and over · Price varies</h5>
            <p>Chakra balancing helping the energy flow being more balanced by unblocking the Chakras, based on your initial consultation.</p>
        </div>
        <div class="card">
            <h3>Crystal Chakra Balancing</h3>
            <h5>30 minutes and over · Price varies</h5>
            <p>Similar to Chakra Balancing with more directed healing energies based on your animals needs/case.</p>
        </div>
        <Footer />
        </>
    );
  }
  
  export default Sessions;
